// Lunchguide
#matistan {
    height: 600px;
	margin-top: -176px;
	position: relative;
	background-position: center center;
	background-size: cover;


	#bg-img {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-position: center center;
		background-size: cover;
		transition: .5s;
		transition-timing-function: max-height cubic-bezier(0, 1, 0.5, 1);
	}

	.lunch-city {
		width: auto;
		display: inline-block;
		@include align-both();
		opacity: 1;
		transition: 1s;

		h1 {
			display: inline-block;
		}
	}

	.dd-holder {
		display: inline-block;
		margin-left: 15px;
		position: relative;
	    width: 165px;
	    display: inline-block;
	}

	.button-takeaway{
	    display: block;
	    background: $green;
	    padding: 20px 30px;
	    border-radius: 30px;
	    font-size: 20px;
	    text-transform: uppercase;
	    font-weight: $semibold;
	    color: $white;
	    transition-timing-function: max-height cubic-bezier(0, 1, 0.5, 1);
	    transition: .3s;
	    max-height: 63px;
	    overflow: hidden;
	    cursor: pointer;
		width: 80%;
		border: none;
		outline: none;
		margin: 30px auto;
		position: relative;

		&:hover {
	    	border-bottom: none;
	    	background: darken($green, 10%);
		}
		&:active{
			background: darken($green, 30%);
			transform: scale(0.96);
		}

		img{
			height: 20px;
			position: absolute;
			margin-left: -40px;
			transition: .2s;
			opacity: 0;
		}

		&.is-active{
			background: darken($green, 20%);

			img{
				opacity: 1;
			}
		}
	}

	.dropdown {
		position: absolute;
	    display: inline-block;
	    background: $green;
	    padding: 20px 47px 20px 30px;
	    border-radius: 30px;
	    font-size: 20px;
	    text-transform: uppercase;
	    font-weight: $semibold;
	    color: $white;
	    transition: .3s;
	    transition-timing-function: max-height cubic-bezier(0, 1, 0.5, 1);
	    max-height: 63px;
	    overflow: hidden;
	    cursor: pointer;
	    top: -52px;
		min-width: 165px;
		z-index: 100;
		box-shadow: 0 0 20px rgba(0,0,0,0.4);

	    span {
		    padding-bottom: 4px;
		    display: block;
	    }

	    &:hover {
	    	border-bottom: none;
	    	background: $blue;
	    }

		&:after {
		    content: '';
		    display: block;
		    position: absolute;
		    right: 27px;
		    top: 25px;
			width: 7px;
			height: 7px;
		    border: 2px solid white;
		    border-left: 0;
		    border-top: 0;
		    transform: rotate(45deg);
		    transition: .2s;
		}

		.dd-item {
			display: block;
			padding-top: 13px;
			transition: .2s;
			color: $white;
			opacity: 0;
		}

		&.open {
			max-height: 150px;

			&:after {
			    transform: rotate(-135deg);
			}

			.dd-item {
				opacity: 1;
			}
		}
	}

	@include below($m) {

		.dd-holder {
	    	width: 155px;
	    }

		.dropdown {
		    padding: 15px 42px 15px 25px;
		    border-radius: 24px;
		    font-size: 15px;
		    max-height: 48px;
		    top: -39px;
		    min-width: 155px;

		    &:after {
			    right: 22px;
				top: 17px;
			}
		}
	}

	@include below($s) {

		h1.title.xl {
			font-size: 57px;
			display: block;
			text-align: center;
		}

		.dd-holder {
	    	margin-left: 0;
	    }

		.dropdown {
			top: -10px;
		}
	}
}

.lunch-nav{
	font-size: 0;
	text-align: center;
    padding: 20px 20px 40px;
    max-width: 800px;
    margin: 0 auto;


	.lunch-nav-button{
		display: inline-block;
		width: calc(100% / 6);
	}

	a {
		font-size: 22px;
		color: #fff;
		display: inline-block;

	}

	.is-active a {
		color: $yellow;
		border-bottom: solid 3px $yellow;
	}

	@include below($s) {
		margin-bottom: 10px;

		span {
			display: none;
		}

		a {
			font-size: 16px;
		}
	}
}

.lunch{
	padding: 20px 20px 100px;
	max-width: 800px;
	margin: 0 auto;
	display: none;
	min-height: 600px;

	a{
		color: $white;
	}
}
.restaurant{
	position: relative;
    padding: 20px 20px 20px 110px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.0);
    border-bottom: 1px solid rgba(255, 255, 255, 1);
    display: block;
    text-align: left;
    min-height: 150px;

    .restaurant-logo{
		position: absolute;
		display: block;
		left: 0px;
		width: 80px;
		height: 80px;
		background-color: #fff;
		background-position: center;
		background-size:  80% auto;
		background-repeat: no-repeat;
    }

	h3{
		font-size: 24px;
		margin-bottom: 10px;
	}

    p{
    	font-size: 16px;
    	line-height: 20px;
		font-family: $secondaryFont;
		margin: 10px 0;

		&:before{
			content: "-";
			display: inline-block;
			margin-right: 10px;
		}
    }

	.metadata{
		margin-top: 20px;
		color: rgba(255,255,255, .7);

		a,
		span{
			color: inherit;
	    	display: inline-block;
	    	margin: 5px 10px 5px 0;
	    	font-family: $secondaryFont;
	    	font-size: 16px;
	    	line-height: 20px;

	    	&:before{
	    		display: inline-block;
	    		margin-right: 10px;
	    	}
		}
	}

	@include below($s){
		padding-left: 0;


		.restaurant-logo{
			position: relative;
			margin: 0 auto 20px;
		}

	}
}

.day {
	display: none;

	&.is-active {
		display: block;
	}
}



.takeaway{
	display: none;
}

.show-takeaway {

	.standard{
		display: none !important;
	}
	
	.takeaway{
		display: block !important;
	}

}