.content-holder-page {
	color: $white;
	max-width: 700px;
    margin: 0 auto 120px auto;

	h1, h2, h3, h4, h5, h6, ul, li, p {
		color: $white;
		margin-bottom: 20px;
	}
	h2 {
		font-size: 28px;
	}
	ul {
		list-style-position: inside;
		padding-left: 20px;
    }
    p, ul {
        font-family: $secondaryFont;
        font-weight: 400;
        font-size: 20px;
        line-height: 1.5;
        margin-bottom: 40px;
    }
    h3 {
        font-size: 24px;
    }

    .button.round{
        padding-left: 50px;
        padding-right: 50px;
        border-radius: 100px;
    }

    .info-box{
        padding: 34px 52px;
        margin-bottom: 30px;

        .button{
            z-index: 2;
            top: 50%;
            position: absolute;
            transform: translate(0%, -80%);
            right: 50px;
        }

        p{
            position: relative;
            z-index: 2;
            margin: 15px 0;
        }


        @include below($s){
            .info-box{
                padding: 34px 34px;
            }
            
            img{
                display: block;
                margin: 0 auto;
                margin-top: 15px;
                margin-bottom: 15px;
            }

            .button{
                position: relative;
                transform: 0;
                transform: translate(0%, 0%);
                right: auto;
                width: 100%;
                text-align: center;
                padding-left: 10px;
                padding-right: 10px;
            }

            &:after{
                bottom: 0;
                top: 0;
                transform: skewX(110deg);
                display: none;
            }
        }
    }

    .bg-split {
        overflow: hidden;
        position: relative;
        background: #F1E967;
        z-index: 1;
       
        &:after{
            content: '';
            z-index: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #0a8886;
            transform: skewX(20deg);
            right: -50%;
            top: 0;
        }

      p {
          color: #3c5e5d;
      }
   
    }

    .bg-green {
        background-color: #3AB359;
    }
    
    .bg-blue {
        background-color: #0A8886;
        color: #fff;
    }
    
    .bg-yellow {
        background-color: #F1E967;

        p {
            color: #3c5e5d;
        }
    }
    
}

.image-holder-center{
    position: relative;
    max-width: 1440px;
    margin: 30px auto 30px;

    img{
        max-width: 100%;
    }

    .button.round{
        position: absolute;
        left: 50%;
        bottom: 40px;
        transform: translate(-50%, 0);
        padding-left: 50px;
        padding-right: 50px;
        border-radius: 100px;

        @include below($s){
            position: relative;
            left: auto;
            bottom: auto;
            transform: translate(0, 0);
            width: 70%;
            text-align: center;
            display: block;
            margin: 20px auto;
        }
    }
}