body.error404 {
	height: 100vh;

	footer {
		position: absolute;
		bottom: 0;
		width: 100%;
	}

	@include below($s) {
		height: initial;

		footer {
			position: relative;
			bottom: initial;
		}
	}
}