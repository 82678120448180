.link-img {
    padding-bottom: 50px;
    margin-bottom: 0;
    min-height: 90vh;
	
	.holder, .row {
		position: static;
	}

	.title {
	    z-index: 11;
	    position: relative;
	}

	.link-holder {
		padding-top: 20vh;

		@include below($s) {
			padding-top: 0;
		}
	}

	.link {
		z-index: 8;

	    @include below($s) {

	    	.title {
				line-height: 1.2;
				margin-bottom: 25px;
				display: inline-block;
				padding-right: 25px;
				font-size: 32px; 

				&.arrow:after {
					top: 9px;
					right: -15px;
					position: relative;
				}	    		
	    	}
	    }
	}

	.bg-img {
		display: block;
		position: absolute;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		opacity: 1;
		z-index: 0;
		transition: .3s;
		pointer-events: none;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: linear-gradient(to left, rgba(0,0,0,0.2), rgba(0,0,0,0.7));
		}
	}

	.link {

		.bg-img {
			opacity: 0;
			z-index: 1;
		}

		&:hover {

			.bg-img {
				opacity: 1;
			}
		}
	}
}