#top {
	padding: 50px 50px 0;
	z-index: 2;
  display: flex;

  .menu-wrapper {
    width: 40%;
    padding-top: 30px;
    display: flex;

    .menu-meny-container {
      padding-left: 10px;

      ul {
        list-style-type: none;

        li {
          float: left;
          text-decoration: none;
        }
      }
      
      .menu-item {
        a {
          display: block;
          padding-right: 10px;
          color: $green;
          font-weight: $semibold;
          text-transform: uppercase;
          border-bottom: 0;
    
          &:hover {
            color: $yellow;
          }
        }
      }
    }
  }
  
  .logo-wrapper {
    width: 20%;
    text-align: center;
  }

	.social {
    width: 40%;
    padding-top: 30px;
    text-align: end;

		.icon {
			width: 20px;
			height: 20px;
			background-repeat: no-repeat;
			display: inline-block;
			transition: .2s;

			&.facebook {
				width: 10px;

				&:hover {
					background-image: url('../img/icon_facebook_yellow.svg') !important;
				}
			}

			&.instagram {

				&:hover {
					background-image: url('../img/icon_instagram_yellow.svg') !important;
				}
			}

			&:first-child {
				margin-right: $padding;
			}
		}
	}

	@include below(600px) {
		padding: $padding $padding 0;
		overflow: visible;

    .menu-wrapper {
      width: 75%;
    }

		.logo-wrapper {
			width: 25%;

      .logo {
        padding-top: 16px;
        img {
          
          height: 40px;
        }
      }
		}

    .social {
      display: none;

      .icon {
        &:first-child {
          margin-right: 10px;
        }
      }
    }
	}
}

section#top-text {
	padding-top: 20px;
	margin-bottom: 20px;

	@include below($s) {
		margin-bottom: 0;
	}
}