.event {
	position: relative;
	background-size: cover;
	background-position: center center;
	height: 490px;

	.text-holder {
	    bottom: 0;
	    position: absolute;
	    padding: 20px 20px 75px;
	    width: 100%;
	    z-index: 1;
	}	

	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.7));
	}

	h3.title {
		border-bottom: solid 3px transparent;
		display: inline-block;
		line-height: 1.3;
		margin-bottom: 2px;
	}

	&:hover h3.title {
		border-bottom: solid 3px #fff;
	}
}

a.button.tickets {
	top: 450px;
	left: 20px;
	position: absolute;
	z-index: 3;
	border-bottom: 0;
}

@include below($s) {

	.swiper-slide {

		p:first-child {
			color: rgba(255,255,255,0);
			transition: .2s;
		}

		&.swiper-slide-active p:first-child {
			color: $white;
		}
	}
}