.single-event {
	position: relative;
	
	&:before {
		content: '';
		position: absolute;
		top: 0;
		height: 560px;
		right: 0;
		left: 0;
		background: $black;
	}

	.image-holder {
	    width: 100%;
	    height: 560px;
	    background-size: cover;
	    background-position: center center;
	    overflow: hidden;
	    position: relative;
	    max-width: 1440px;
	    margin: 0 auto;

	    .close {
	    	font-size: 20px;
	    	font-weight: $semibold;
	    	color: $white;
		    background: $green;
		    position: absolute;
		    cursor: url('../img/icon_close.png'), pointer;
			transform: rotate(-30deg);
			padding: 50px 240px 20px 120px;
			top: -50px;
			left: -110px;

			&.to-start {
				display: none;
			}

		    @include below($m) {
		    	font-size: 16px;
				padding: 60px 250px 10px 120px;
				top: -65px;
				left: -120px;
		    }
	    }
	} 

	.text-holder {
		background: $white;
		padding: 5*$padding;
		font-size: 0;

		.left, .right {
			width: 60%;
			display: inline-block;
			vertical-align: top;
		}

		.right {
			width: 40%;
			text-align: right;
			position: relative;
			float: right;
			padding-left: 2*$padding;
		}

		.left .title {
			line-height: 1.2;
			margin-bottom: $padding;
		}

		.button-holder {
		    text-align: center;
		    width: 279px;
		    right: 0;
		    position: absolute;
		}

		.square {
			border-radius: 0;
			padding: 40px 70px;
		}

		.social {
			margin-top: 210px;
		}

		.info {
			margin-bottom: 50px;
		}

		@include below($l) {
			padding: 2*$padding;

			.button-holder {
				width: 239px;
			}

			.square {
				padding: 30px 50px;
			}
		}

		@include below($m) {
			padding: 0;

			.button.square {
				margin-top: 0;
				width: 100%;
				padding: 40px 70px;
			}

			.left {
				padding: 2*$padding;
				width: 100%;

				@include below($s) {
					padding: $padding;
				}
			}

			.right {
				width: 100%;
				text-align: center;
				padding-left: 0;
				float: none;

				.button-holder {
					width: 100%;
					position: relative;
				}

				.social {
					display: none;
				}
			}
		}
	}

	.link.event {
		display: inline-block;
		position: relative;
		height: 60px;
		transition: .2s;
		padding-right: 70px;

		&:after {
			content: '';
			width: 50px;
			height: 50px;
			background-size: cover;
			background-position: center center;
			position: absolute;
			transition: .2s;
			top: -17px;
			right: 0;
			left: auto;
		}

		&.spotify:after{
			background-image: url('../img/icon_spotify.svg');
		}

		&.facebook:after {
			background-image: url('../img/icon_facebook_circle.svg');
		}

		&.twitter:after {
			background-image: url('../img/icon_twitter.svg');
		}

		&:active, &:visited, &:focus {
			color: $green;
		}

		&:hover {
			color: $blue;

			&.spotify:after{
				background-image: url('../img/icon_spotify_blue.svg');
			}

			&.facebook:after {
				background-image: url('../img/icon_facebook_circle_blue.svg');
			}

			&.twitter:after {
				background-image: url('../img/icon_twitter_blue.svg');
			}
		}
	}
}

body.single-event {	

	.single-event {
		background: $white;
	}

	.close {
		display: none;
	}

	@include below($m) {
		
		.single-event {
			background: $white;
		    position: absolute;
		    top: 0;
		    height: auto;
		    z-index: 100;
		    width: 100%;
		}

		.close {
			display: none;

			&.to-start {
				display: block !important;
			}
		}
	}

	.text-holder {
	    width: 1200px;
	    max-width: $contentMaxWidth;
	    padding: 5*$padding $padding;
	    margin: 0 auto;

	 //    @include below($l) {
		// 	padding: 2*$padding;
		// }

		@include below($m) {
			padding: 0;
			max-width: 100%;

			.button.square {
				margin-top: 0;
				width: 100%;
			}

			.left {
				padding: 2*$padding;
				width: 100%;

				@include below($s) {
					padding: $padding;
				}
			}

			.right {
				width: 100%;
				text-align: center;
				padding-left: 0;
				float: none;

				.button-holder {
					width: 100%;
					position: relative;
				}

				.social {
					display: none;
				}
			}
		}
	}
}

.single-event .text-holder .social.only-mobile {
	margin: 3*$padding 0;
	display: none;
	text-align: center;

	.link {
		width: 50px;
		height: 50px;
		margin: 0 10px;
		padding: 0;

		&:after {
			top: 0;
		}
	} 

	@include below($m) {
		display: block;
	}
}

#wait {
	position: fixed; 
	display: none; 
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0,0,0,.6);
	z-index: 19;

	.loading {
		position: absolute; 
		width: 64px; 
		height: 64px; 
		background-image: url('../img/loading_symbol.svg');
		background-size: cover;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.popup {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 20;
	background: rgba(0,0,0,.6);
	display: none;
	padding: 5*$padding;
	cursor: url('../img/icon_close.png'), pointer;
	overflow: auto;

	@include below($l) {
		padding: 2*$padding;
	}

	@include below($m) {
		padding: $padding;
	}

	@include below($s) {
		padding: 0px;
	}

	.inner {
		height: auto;
		width: 100%;
		max-width: 1200px;
		background-color: #fff;
		margin: 0 auto;
		cursor: initial;
	}
}