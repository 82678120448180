section.filtering {
	padding-top: 0 !important;
}

#filter-events {
	max-width: 800px;
	margin: 0 auto;

	.search-holder {
		font-size: 0;
		margin: 0 0 30px;
		position: relative;

		&:before {
			content: '';
			display: block;
			position: absolute;
			width: 22px;
			height: 23px;
			background-image: url(../img/icon_search.png);
			background-size: cover;
			background-position: center center;
			top: 9px;
			left: 0;
		}

		input {
			border: none;
			border-bottom: solid 3px #000;
			padding: 0 15px 0 35px;
			margin-bottom: 10px;
			width: 100%;
			height: 40px;
			font-size: 18px;
			font-family: $secondaryFont;
			color: $white;
			line-height: 40px;
			transition: .2s;
			background: transparent;
			border-radius: 0;

			&::placeholder {
				color: $white;
				font-size: 16px;
				font-family: $mainFont;
			}

			&:hover {
				border-color: $white;
			}
		}

		input:focus {
			outline: none;
			border-color: $white;
		}
	}

	ul.list {
		list-style: none;
	}

	.list-item {
		margin-bottom: 15px;
		position: relative;

		.open-event {
			border-bottom: none;

			&:hover {
				border-bottom: none;
			}
		}

		.img-icon {
		    width: 81px;
		    height: 81px;
		    background-size: cover;
		    background-position: center center;
		    vertical-align: top;
		    position: absolute;
		}

		.text-holder {
			padding-left: 105px;

			p {
				font-size: 16px;
				margin-bottom: 0;
			}

			h3.title {
				font-size: 24px !important;
				border-bottom: 3px solid transparent;
				line-height: 1.3;
			}
		}

		&:hover {

			h3.title {
				border-bottom: 3px solid $white;
				display: inline-block;
			}
		}

		.button.tickets {
			position: absolute;
			right: 0;
			left: initial;
			top: 0;
			margin: 0;
			@include vertical-align(absolute);
		}

		@include below($s) {
			margin-bottom: 25px;

			.img-icon {
				top: 45px;
			}

			.text-holder {
				padding-left: 0;

				h3.title {
					font-size: 23px !important;
					margin-bottom: 5px;
				}

				p {
					padding-left: 100px;
				}
			}

			.button.tickets {
				position: relative;
				transform: none;
				top: initial;
				margin-left: 100px;
				margin-top: 10px;
				padding: 5px 20px;
			}
		}
	}
}