// Gone is the desktop, table and mobile. Let's use s, m, l, xl.
$s: 500px;
$m: 800px;
$l: 1050px;
$xl: 1410px;

// Default content settings.
$contentWidth: 1240px;
$contentMaxWidth: 95%;
$padding: 20px;

// Fonts.
$regular: 400;
$semibold: 600;
$mainFont: "bree",sans-serif;
$secondaryFont: "franklin-gothic-urw",sans-serif;

// Colors
$blue: #0A8886;
$green: #3AB359;
$yellow: #F1E967;
$black: #19191E;
$white: #fff;
$gray: #838383;

body {
	background: $black;
	font-family: $mainFont;
}

p {
	color: $white;
	font-family: $mainFont;
	font-weight: $regular;
	font-size: 20px;
	line-height: 1.5;
}

.title {
	color: $white;
	font-family: $mainFont;
	font-weight: $semibold;
	line-height: 1.5;
}

.secondary, .secondary p {
	font-family: $secondaryFont;
}

.xxxs {
	font-size: 18px;
}

.xxs {
	font-size: 20px;
}

.xs {
	font-size: 24px;
}

.s {
	font-size: 30px !important;

	@include below($s) {
		font-size: 26px !important;
	}
}

.m {
	font-size: 36px;

	@include below($m) {
		font-size: 30px;
	}

	@include below($s) {
		font-size: 28px;
	}
}

.l {
	font-size: 64px;

	@include below($m) {
		font-size: 40px;
	}

	@include below($s) {
		font-size: 36px;
	}
}

.xl {
	font-size: 70px;

	@include below($m) {
		font-size: 57px;
	}

	@include below($s) {
		font-size: 36px;
	}
}

.blue {
	color: $blue;
}

.green {
	color: $green;
}

.yellow {
	color: $yellow;
}

.black, .black p {
	color: $black;
}

.gray {
	color: $gray;
}

a {
	text-decoration: none;
	position: relative;
	transition: .2s;
	border-bottom: solid 3px transparent;

	&:hover {
		border-bottom: solid 3px #fff;
	}

	&.blue:hover {
		border-color: $blue;
	}

	&.yellow:hover {
		border-color: $yellow;
	}

	&.arrow {

		&:after {
			content: '';
			display: inline-block;
		    position: absolute;
			background: url('../img/arrow.png');
			background-size: cover;
			width: 40px;
			height: 32px;
		    right: -50px;
		    top: 50%;
		    transform: translateY(-50%);
		    transition: .2s;
		}

		&:hover {

			&:after {
				right: -60px;
			}
		}

		@include below($m) {

			&:after {
				width: 30px;
				height: 24px;
			}
		}

	    @include below($s) {
	    	display: block;
	    	padding-right: 30px;

	    	&:hover {
	    		border-bottom: 0;

	    		&:after {
	    			right: 0;
	    		}
	    	}

	    	&:after {
				width: 25px;
				height: 20px;
				right: 0;
				top: auto;
				bottom: 8px;
	    	}
	    }
	}

	&.button {
	    font-size: 15px;
	    text-transform: uppercase;
	    font-weight: $semibold;
	    color: $white;
	    background: $green;
	    padding: 15px 30px;
	    border-radius: 24px;
	    margin: 15px 0;
	    display: inline-block;
	    transition: .2s;

	    &:hover {
	    	border-bottom: none;
	    	background: $blue;
	    }
	}

	&.no-border {
		border-bottom: 0 !important;

		&:hover, &:active, &:visited {
	    	border-bottom: 0 !important;
	    }
	}

	&.yellow-hover {

		&:hover {
			color: $yellow;
		}
	}
}

.mb {
	margin-bottom: $padding;
}

.smb {
	margin-bottom: $padding/2;
}

.sb {
	font-weight: $semibold;
}

.pwm p {
	margin-bottom: 10px;
}

.uppercase {
	text-transform: uppercase;
}

::selection {
	background: $blue;
	color: $white;
}

.section-title {

	@include below($s) {
		margin-bottom: 130px;
	}
}

section {
	position: relative;
	width: 100%;
	overflow: hidden;
	padding-top: 50px;
	margin-bottom: 50px;
}

.holder {
    width: $contentWidth;
    max-width: $contentMaxWidth;
    padding: 0 $padding;
    position: relative;
    margin: 0 auto;
}

.row {
    width: 100%;
    position: relative;
    font-size: 0;
    padding: 0 $padding $padding 0;
    font-size: 0;

    &.center {
    	text-align: center;
    }
}