@import 'normalize';
@import 'entypo';
@import 'breaker';
@import 'mixins';
@import 'swiper';
@import 'basics';
@import 'top';
@import 'footer';
@import 'event-slider';
@import 'filtering';
@import 'single-event';
@import 'link-section';
@import 'not-found';
@import 'lunch';
@import 'default-page';


.image-section{
	text-align: center;
}

pre {
	font-size: 20px;
	color: white;
}

