footer {
	background: $blue;
	position: relative;
	overflow: hidden;

	.holder {
		z-index: 3;
	
		.row {
	    	padding: 150px 0 150px 0;
		}
	}

	p {
		max-width: 300px;
	}

    &:before {
		content: '';
		z-index: 2;
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: $green;
		transform: skewX(-35deg);
		left: -55%;
		top: 0;
    }

    &:after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: $yellow;
		transform: skewX(30deg);
		right: -50%;
		top: 0;
    }

	.box {
		width: calc((100%/3));
		display: inline-block;
		vertical-align: top;

		&#contact {
			margin-right: calc((100%/3) - 1px);

			.title {
				line-height: 1.2;
				margin-bottom: $padding;
			}
		}
	}

    @include below($s) {
    	background: $yellow;

    	&:before {
			transform: rotate(-72deg);
			left: 0;
			top: -310px;
			height: 810px;
    	}

    	&:after {
    		display: none;
    	}

    	.row {
			padding: 50px 0 60px 0 !important;

	    	.box {
			    width: 100%;
			    margin-right: 0;
			    display: block;

			    &:first-child {
			    	margin-bottom: 170px;
			    }

			    .l {
			    	font-size: 28px;
			    }
	    	}
    	}
    }
}